<template>
  <div>
    <router-view v-show="$route.matched.length==3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length==2">
      <BusinessList/>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from "@/layouts/PageHeaderLayout";
import BusinessList from './List'

export default {
  name: "BusinessIndex",
  components: {
    PageHeaderLayout, BusinessList
  }

}
</script>

<style scoped>

</style>
